<template>
    <!-- Modal -->
    <div class="modal fade" id="upload-stock" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Add stock</h5>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status> 

                            <div class="form-row">
                                <label class="mb-2 fw-bold" for="tags">Tags</label>

                                <div class="input-group">
                                    <input type="file" class="form-control" id="file" @change="imageEvent($event)">
                                </div>
                                <small class="d-block">Enter comma-separated tags for the current stock</small>
                            </div>                       
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col d-flex">
                            <button class="btn bg-orange text-white ms-auto" @click="handleSubmit()">
                                <span class="m-0 text-uppercase" style="letter-spacing: 1px">Finish </span> <font-awesome-icon class="ms-3" :icon="['fas', 'angle-right']"></font-awesome-icon>
                            </button> 
                        </div>    
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';

    .form-select, .form-control {
        border: 1px solid #ced4da;
    }    
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'UpdateStock',
        props: {
            supplier_id: {
                required: false,
                type: Number
            }
        },
        components: {
            Status
        },
        data() {
            return {
                screen: 0,
                data: {                        
                    file: null
                },
                errors: {},
                status_data: {
                    "success": false,
                    "success_message": null,
                    "error": false,
                    "error_message": null,
                    "loading": false
                },
            }
        },
        methods: {
            imageEvent(event) {
                this.data.file = event
            },
            handleSubmit() {
                this.status_data.loading = true
                let data = new FormData();

                data.append('file', data.append('file', this.data.file.target.files[0]))

                this.axios.post('api/upload-stocks', data).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'Stocks Added!'

                }).catch(errors => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = 'Errors found!'
                    this.errors = errors.data

                    console.log(errors.data);
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
        }
    }
</script>