<template>
  <transition v-if="!loading" name="fade">
    <div id="wrapper">
        <sidebar :page="'Manage Stock'" :sidebar_data=sidebar_data></sidebar>
        <div class="body">
            <div class="backdrop" v-on:click="close()"></div>
              
            <admin-header :title="'Manage Site'" :tagline="'All your controls, in one place.'" v-model:all_screens=all_screens v-model:screen=screen v-model:url=url :user=user style="margin-bottom: 3rem;"></admin-header>

            <div class="container mb-4">
              <div class="content-wrapper">
                <div class="content mx-auto">

                  <!--  List 1 -->
                  <auto-list v-if="url != null" v-model:all_screens=all_screens v-model:screen="screen" :title=title v-model:url="url" v-model:list_data="list_data"></auto-list>

                </div>                                          
              </div>
            </div>
            
            <main-footer></main-footer>
        </div>

        <upload-stock></upload-stock>
    </div>
  </transition>
</template>

<script>
import MainFooter from '../../components/Footer'
import Sidebar from '../../components/AutoSidebar'
import AdminHeader from '../../components/Header'
import AutoList from '../../components/AutoList'
import SidebarData from '../../assets/json/Admin/SidebarData'
import UploadStock from '../../components/Modals/UploadStock'

export default {
  name: 'AdminStock',
  components: {
    AdminHeader,
    MainFooter,
    Sidebar,
    AutoList,
    UploadStock
  },
  data() {
    return {
      url: 'api/stocks',
      title: {status: true, title: 'Your Data', actions: [
        {name: 'Add Stock', type: 'modal', action_url: 'add-stock'},
        {name: 'Upload Stock', type: 'modal', action_url: 'upload-stock'}
      ]},
      all_screens: [
        {title: 'All Stock', screen: 1, url: 'api/active-users', actions: [
          {name: 'Edit', type: 'modal', action_url: 'edit-stock'},
          {name: 'Add to Ad Space', type: 'modal', action_url: 'featured-ad'},
        ]}              
      ],
      sidebar_data: SidebarData,
      screen: 1,
      list_data: []         
    }
  },
  watch: {
    user: function() {
      // this.ifNotAdmin()
    }
  },  
  computed: {
    user () {
      return this.$store.state.user
    },
    loading () {
      return this.$store.state.loading
    }       
  },    
  mounted() {
    this.$store.dispatch("getUser")
  },
  methods: {
    ifNotAdmin() {
      if( !this.user.admin) {
        this.$router.push({name: 'Home'});
      }
    },    
  }
}
</script>
